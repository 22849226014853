


























































































import api from "@/plugins/api";
import {Component, Vue} from 'vue-property-decorator';
import FetchStatus from "@/typescript/enums/FetchStatus";
import ITicket from "@/typescript/interfaces/ITicket";
import EventManager from "@/plugins/EventManager";
import LoginState from "@/typescript/enums/LoginState";
import Dialogs from "@/typescript/enums/Dialogs";

@Component
export default class extends Vue {
    status: FetchStatus = FetchStatus.Fetching;

    get tickets(): ITicket[] {
        return this.$store.getters['GET_TICKETS'];
    }

    get FetchStatus(): unknown {
        return FetchStatus;
    }

    get StatusText(): string {
        switch (this.status) {
            case FetchStatus.Fetching:
                return 'Adicionando...';
            case FetchStatus.Fetched:
                return 'Adicionado!';
            case FetchStatus.Error:
                return 'Falha';
        }

        return '';
    }

    get Id(): string {
        return this.$route.params.code;
    }

    get loginState(): LoginState {
        return this.$store.getters['GET_LOGIN_STATE'];
    }

    private get dialogManager(): EventManager {
        return this.$store.getters['GET_DIALOG_MANAGER'];
    }

    get isDarkTheme(): boolean {
        return this.$vuetify.theme.dark;
    }

    async mounted(): Promise<void> {
        try {
            while (this.loginState === LoginState.Pending) {
                await new Promise(resolve => setTimeout(resolve, 100));
            }

            if (this.loginState !== LoginState.LoggedIn) {
                this.dialogManager.dispatch(Dialogs.LoginDialog, 'Para sua segurança é necessário entrar para adicionar tickets.');
                return;
            }

            await new Promise(resolve => setTimeout(resolve, 250));

            const {data: newTicket} = (await api.FETCH_TICKET<ITicket>(this.Id));

            if (this.tickets.filter(ticket => ticket.code === newTicket.code).length === 0) {
                await this.$store.dispatch('INSERT_TICKET', newTicket);
            }

            this.status = FetchStatus.Fetched;
            await new Promise(resolve => setTimeout(resolve, 1250));
            await this.$router.push("/");
        } catch (e) {
            this.status = FetchStatus.Error;
            console.error(e);
        }
    }
}

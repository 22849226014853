

























































































































import QrCode from 'qrcode.vue';
import VueBarcode from 'vue-barcode';
import Package from "../../package.json";
import Toast from '@/components/toast.vue';
import Dialogs from "@/typescript/enums/Dialogs";
import EventManager from "@/plugins/EventManager";
import TicketList from "@/components/ticketList.vue";
import {Component, Vue} from 'vue-property-decorator';
import ITicket from "@/typescript/interfaces/ITicket";
import LoginState from "@/typescript/enums/LoginState";

@Component({
    components: {
        Toast,
        QrCode,
        VueBarcode,
        TicketList
    },
})
export default class extends Vue {
    get Package(): Record<string, unknown> {
        return Package;
    }

    get tickets(): ITicket[] {
        return this.$store.getters['GET_TICKETS'];
    }

    get LoginState(): typeof LoginState {
        return LoginState;
    }

    get loginState(): LoginState {
        return this.$store.getters['GET_LOGIN_STATE'];
    }

    get dialogManager(): EventManager {
        return this.$store.getters['GET_DIALOG_MANAGER'];
    }

    get isPwa(): boolean {
        return this.$store.getters['GET_IS_PWA'];
    }

    get userIcon(): string {
        return this.$store.getters['GET_USER_ICON_CACHED'];
    }

    get isOnline(): boolean {
        return this.$store.getters['GET_ONLINE_STATE'];
    }

    get isReloading(): boolean {
        return this.$store.getters['GET_RELOADING_STATE'];
    }

    get lastUpdate(): string {
        return this.$store.getters['GET_LAST_UPDATE'];
    }

    private async selectTicket(ticket: ITicket): Promise<void> {
        const index = this.tickets.indexOf(ticket);
        this.dialogManager.dispatch(Dialogs.TicketCard, index);
    }

    private openLoginDialog(): void {
        this.dialogManager.dispatch(Dialogs.LoginDialog, 'Entre para ter seus tickets novamente.');
    }

    private openPwaDialog(): void {
        this.dialogManager.dispatch(Dialogs.PwaDialog);
    }

    private openNetworkDialog(): void {
        this.dialogManager.dispatch(Dialogs.NetworkDialog);
    }
}











































import {Component, Vue, Watch} from "vue-property-decorator";
import Dialogs from "@/typescript/enums/Dialogs";
import EventManager from "@/plugins/EventManager";
import AddDialog from "@/components/addDialog.vue";
import TicketCard from "@/components/ticketCard.vue";
import SideButton from "@/components/sideButton.vue";
import LoginDialog from "@/components/loginDialog.vue";
import ContextMenuAction from "@/typescript/enums/ContextMenuAction";
import PwaDialog from "@/components/pwaDialog.vue";
import UpdateDialog from "@/components/updateDialog.vue";
import NetworkDialog from "@/components/networkDialog.vue";
import LoginState from "@/typescript/enums/LoginState";
import LocationDialog from "@/components/locationDialog.vue";

@Component({
    components: {LocationDialog, NetworkDialog, UpdateDialog, PwaDialog, SideButton, TicketCard, LoginDialog, AddDialog}
})
export default class App extends Vue {

    private get dialogManager(): EventManager {
        return this.$store.getters['GET_DIALOG_MANAGER'];
    }

    private get isOnline(): boolean {
        return this.$store.getters['GET_ONLINE_STATE'];
    }

    private get loginState(): LoginState {
        return this.$store.getters['GET_LOGIN_STATE'];
    }

    get isDarkTheme(): boolean {
        return this.$vuetify.theme.dark;
    }

    set isDarkTheme(newValue: boolean) {
        this.$vuetify.theme.dark = newValue;
    }

    private isVisible: boolean = true;

    private onSideButtonAction(action: ContextMenuAction) {
        switch (action) {
            case ContextMenuAction.Add:
                this.dialogManager.dispatch(Dialogs.AddDialog);
                break;
            case ContextMenuAction.Login:
                this.dialogManager.dispatch(Dialogs.LoginDialog);
                break;
            case ContextMenuAction.Logout:
                this.$store.dispatch('LOGOUT');
                break;
            case ContextMenuAction.Warning:
                this.dialogManager.dispatch(Dialogs.PwaDialog);
                break;
            case ContextMenuAction.Theme:
                this.isDarkTheme = !this.isDarkTheme;
                break;
        }
    }

    mounted(): void {
        document.addEventListener('visibilitychange', () => {
            this.isVisible = !document.hidden;
        });
        window.addEventListener('resize', () => this.updateScreenSize());
        this.updateScreenSize();
    }

    created(): void {
        window.addEventListener("outdated", () => {
            this.dialogManager.dispatch(Dialogs.UpdateDialog);
        })

        if (localStorage.getItem("welcome") !== "1") {
            this.$router.push("/welcome")
        }

        let epoch = 0;
        setInterval(() => {
            epoch++;

            if (this.isOnline && epoch < 10)
                return;

            epoch = 0;
            this.$store.dispatch("CHECK_INTERNET_CONNECTION")
        }, 30000);

        this.isDarkTheme = (localStorage.getItem("theme") || "false") === "true";
        this.$store.commit("SET_LAST_UPDATE", localStorage.getItem("lastUpdate") || "--:--");
        this.$store.dispatch("CHECK_INTERNET_CONNECTION");
        this.$store.dispatch('RESTORE_LOCAL_TICKETS');
        this.$store.dispatch('FETCH_USER_ICON');
    }

    updateScreenSize(): void {
        this.$store.dispatch('UPDATE_SCREEN_WIDTH', window.innerWidth)
        this.$store.dispatch('UPDATE_SCREEN_HEIGHT', window.innerHeight)
    }

    @Watch("isOnline")
    @Watch("isVisible")
    @Watch("loginState")
    onUpdate(): void {
        if (this.loginState === LoginState.LoggedIn && this.isOnline && this.isVisible) {
            this.$store.dispatch('FETCH_USER_TICKETS');
            this.$store.dispatch('FETCH_USER_ICON');
        }
    }

    @Watch("isDarkTheme")
    onThemeChanged(): void {
        localStorage.setItem("theme", this.isDarkTheme.toString());
    }
}

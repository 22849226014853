
















import {Component, Vue, Watch} from 'vue-property-decorator';

@Component({
    props: ["width", "thumbSize", "background", "thumbColor", "thumbBorder"]
})
export default class extends Vue {
    amount: number = 0;
    startX: number = 0;
    isDragging: boolean = false;

    get thumbStyle(): unknown {
        return {
            "width": this.$props.thumbSize,
            "height": this.$props.thumbSize,
            "marginLeft": `${this.amount}px`,
            "background": this.$props.thumbColor,
            "transition": this.isDragging ? 'None' : `margin-left 125ms`,
            "border": this.$props.thumbBorder ? `2px solid ${this.$props.background}` : 'None',
            "display": "flex",
            "alignItems": "center",
            "justifyContent": "center"
        }
    }

    get trackStyle(): unknown {
        return {
            "background": this.$props.background,
            "width": this.$props.width
        }
    }

    get maxX(): number {
        const regex = () => /\d+/g;

        const thumbSizeArr = regex().exec(this.$props.thumbSize);
        const trackSizeArr = regex().exec(this.$props.width);

        const thumbSize = Number.parseInt(thumbSizeArr?.pop() || "0");
        const trackSize = Number.parseInt(trackSizeArr?.pop() || "0");

        return trackSize - thumbSize;
    }

    mounted(): void {
        (this.$refs.thumb as HTMLElement).addEventListener("touchstart", (ev) => this.onDragStart(ev.touches[0].screenX));
        (this.$refs.thumb as HTMLElement).addEventListener("touchmove", (ev) => this.onDrag(ev.touches[0].screenX));
        (this.$refs.thumb as HTMLElement).addEventListener("touchend", () => this.onDragEnd());

        (this.$refs.thumb as HTMLElement).addEventListener("mousedown", (ev) => this.onDragStart(ev.screenX));
        (this.$refs.thumb as HTMLElement).addEventListener("mousemove", (ev) => this.onDrag(ev.screenX));
        (this.$refs.thumb as HTMLElement).addEventListener("mouseleave", () => this.onDragEnd());
        (this.$refs.thumb as HTMLElement).addEventListener("mouseup", () => this.onDragEnd());
    }

    onDragStart(x: number): void {
        this.isDragging = true;
        this.startX = x;
    }

    onDrag(x: number): void {
        if (!this.isDragging) return;
        this.amount = Math.min(this.maxX, Math.max(0, x - this.startX));
    }

    onDragEnd(): void {
        this.isDragging = false;
        this.amount = 0;
        this.startX = 0;
    }

    @Watch("amount")
    async onAmountChange(): Promise<void> {
        await this.$nextTick();
        (this.amount >= this.maxX) && this.$emit("interact");
    }
}

import ImageData from "@/typescript/interfaces/ImageData";
import isNil from "lodash/isNil";
import api from "@/plugins/api";

export default class TicketImageProvider {
    public static Instance: TicketImageProvider;
    public static CacheSize: number = 10;
    private readonly Cache: ImageData[] = [];

    constructor() {
        if (!isNil(TicketImageProvider.Instance)) {
            throw new Error("Already initialized.");
        }
        TicketImageProvider.Instance = this;

        const storageData = localStorage.getItem(TicketImageProvider.name);

        if (storageData) {
            try {
                this.Cache = JSON.parse(storageData);
            } catch (e) {
                this.Cache = [];
                console.error(e);
            }
        }
    }

    public async getImage(url: string, useAuth: boolean = false): Promise<string> {
        for (const data of this.Cache) {
            if (data.url === url)
                return data.base64;
        }

        if (this.Cache.length === TicketImageProvider.CacheSize) {
            this.Cache.splice(0, 1);
        }

        const response = await api.GET_IMAGE(url, useAuth);

        const image = btoa(
            new Uint8Array(response.data as ArrayBuffer)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        const imageData = `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;

        this.Cache.push({
            base64: imageData,
            url
        });

        this.save()
        return imageData;
    }

    public deleteCacheFrom(url: string): boolean {
        for (const data of this.Cache) {
            if (data.url === url) {
                this.Cache.splice(this.Cache.indexOf(data), 1);
                this.save();
                return true;
            }
        }

        return false;
    }

    private save(): void {
        localStorage.setItem(TicketImageProvider.name, JSON.stringify(this.Cache))
    }
}

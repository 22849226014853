











































import isEmpty from "lodash/isEmpty";
import {Component, Vue} from 'vue-property-decorator';
import EventManager from "@/plugins/EventManager";
import Dialogs from "@/typescript/enums/Dialogs";

@Component({
    components: {},
})
export default class extends Vue {
    private show: boolean = false;
    private code: string = "";

    private get dialogManager(): EventManager {
        return this.$store.getters['GET_DIALOG_MANAGER'];
    }

    mounted(): void {
        this.dialogManager.on(Dialogs.AddDialog, this.open);
    }

    private async open(): Promise<void> {
        try {
            const hostname = 'https://ticketclub.app/#/add/';
            const text = await navigator.clipboard.readText();

            if (text.startsWith(hostname)) {
                await this.$router.push(`/add/${text.replace(hostname, '')}`);
                return;
            }
        } catch (e) {
            console.error("Clipboard failed opening dialog.", e);
        }

        this.show = true;
    }

    private add(): void {
        if (isEmpty(this.code)) {
            this.show = false;
            return;
        }

        this.$router.push(`/add/${this.code}`);
        this.show = false;
    }
}





































































































import {Component, Vue} from 'vue-property-decorator';
import Browser from "@/typescript/enums/Browser";
import EventManager from "@/plugins/EventManager";
import Dialogs from "@/typescript/enums/Dialogs";
import isEmpty from "lodash/isEmpty";

@Component({
    components: {},
})
export default class extends Vue {
    private browser: Browser = Browser.Unknown;
    private show: boolean = false;

    get Browser(): typeof Browser {
        return Browser;
    }

    get dialogManager(): EventManager {
        return this.$store.getters['GET_DIALOG_MANAGER'];
    }

    mounted(): void {
        this.dialogManager.on(Dialogs.PwaDialog, this.open);
    }

    private open(): void {
        const isChrome = window.navigator.vendor === "Google Inc." && isEmpty(window.navigator.vendorSub);
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        const isSamsung = /SamsungBrowser/i.test(navigator.userAgent);

        if (isSafari) {
            this.browser = Browser.Safari;
        } else if (isSamsung) {
            this.browser = Browser.Samsung;
        } else if (isChrome) {
            this.browser = Browser.Chrome;
        }

        this.show = true;
    }

    private close(): void {
        this.show = false;
    }
}

























































































































































































































import noop from "lodash/noop";
import QrCode from 'qrcode.vue';
import VueBarcode from 'vue-barcode';
import Toast from '@/components/toast.vue';
import ITicket from "@/typescript/interfaces/ITicket";
import {Component, Vue} from 'vue-property-decorator';
import TicketDisplayType from "@/typescript/enums/TicketDisplayType";
import ICardContextMenuItem from "@/typescript/interfaces/ICardContextMenuItem";
import cloneDeep from "lodash/cloneDeep";
import Dialogs from "@/typescript/enums/Dialogs";
import EventManager from "@/plugins/EventManager";
import api from "@/plugins/api";
import LoginState from "@/typescript/enums/LoginState";
import distance from "@turf/distance";
import axios from "axios";
import ILocation from "@/typescript/interfaces/ILocation";
import SlideButton from "@/components/slideButton.vue";

@Component({
    components: {
        SlideButton,
        Toast,
        QrCode,
        VueBarcode
    },
})
export default class extends Vue {
    private show: boolean = false;
    private selectedTicket: number = -1;
    private executingState: string = "idle";
    private cancelRemove: (() => void) | undefined;

    get noop(): () => void {
        return noop;
    }

    get Ticket(): ITicket {
        if (this.selectedTicket !== -1) {
            return this.tickets[this.selectedTicket];
        } else {
            return {
                code: "Unknown",
                title: "Unknown",
                value: "Unknown",
                owner: "Unknown",
                type: TicketDisplayType.Plain,
                issuer: "Unknown"
            };
        }
    }

    get tickets(): ITicket[] {
        return this.$store.getters['GET_TICKETS'];
    }

    get TicketDisplayType(): typeof TicketDisplayType {
        return TicketDisplayType;
    }

    get loginState(): LoginState {
        return this.$store.getters['GET_LOGIN_STATE'];
    }

    get TicketContextMenu(): ICardContextMenuItem[] {
        return [
            {
                name: 'Apagar',
                label: 'Ação irreversível',
                icon: 'mdi-delete',
                action: () => this.removeTicket()
            }
        ]
    }

    private get dialogManager(): EventManager {
        return this.$store.getters['GET_DIALOG_MANAGER'];
    }

    get isOnline(): boolean {
        return this.$store.getters['GET_ONLINE_STATE'];
    }

    get toast(): Toast {
        return this.$refs.toast as Toast;
    }

    get isDarkTheme(): boolean {
        return this.$vuetify.theme.dark;
    }

    mounted(): void {
        this.dialogManager.on(Dialogs.TicketCard, this.showTicket);
    }

    private showTicket(index: number): void {
        this.selectedTicket = index;
        this.show = true;
    }

    private async dismissTicket(): Promise<void> {
        this.show = false;
        await new Promise(resolve => setTimeout(resolve, 325));
        this.selectedTicket = -1;
    }

    protected async removeTicket(): Promise<void> {
        if (this.loginState !== LoginState.LoggedIn) {
            this.dialogManager.dispatch(Dialogs.LoginDialog, 'Você precisa entrar para remover tickets.');
            return;
        }

        const ticket = cloneDeep(this.Ticket);
        const timeoutInMs = 1500;
        await this.dismissTicket();

        await this.$store.dispatch('REMOVE_TICKET', ticket.code);
        await this.toast.showToast(`'${ticket.title}' foi removido.`, 'Desfazer', timeoutInMs);

        try {
            await new Promise<void>((resolve, reject) => {
                let timeout = setTimeout(() => resolve(), timeoutInMs);

                this.cancelRemove = () => {
                    clearTimeout(timeout);
                    reject();
                }
            });

            (this.$refs.toast as Toast).hideToast();
            await api.DELETE_TICKET(ticket.code);
        } catch {
            await this.$store.dispatch('INSERT_TICKET', ticket);
        }
    }

    private openLocationDialog(): void {
        this.dialogManager.dispatch(Dialogs.LocationDialog);
    }

    private executeTicket(): void {
        this.executingState = "executing";

        this.privateExecuteTicket().then(async (result: boolean) => {
            this.executingState = result ? "success" : "failed";
            await new Promise(r => setTimeout(r, 2000));
            this.executingState = "idle";
        });
    }

    private async privateExecuteTicket(): Promise<boolean> {
        if (this.Ticket.type !== TicketDisplayType.Executor) {
            await this.toast.showToast(`O ticket não é do tipo Executor, como você chegou aqui?`, 'Ok', 1000, false);
            return false;
        }

        if (this.Ticket.location) {
            try {
                const ticketLocation = this.Ticket.location.split(',').map(n => Number.parseFloat(n.trim()));
                const userLocation: number[] = await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition((location: ILocation) => {
                        resolve([location.coords.latitude, location.coords.longitude]);
                    }, reject)
                });

                const distanceInKm = distance(userLocation, ticketLocation);
                const distanceInMeters = Math.round(distanceInKm * 1000);

                if (distanceInKm > 0.55) {
                    await this.toast.showToast(`Você está muito longe da area de execução. (${distanceInMeters}m)`, 'Ok', 1000, false);
                    return false;
                }
            } catch (e) {
                await this.toast.showToast(`Falha ao verificar a distancia. (${e.message})`, 'Ok', 1000, false);
                return false;
            }
        }

        try {
            const url = new URL(this.Ticket.value);

            if (!new RegExp(/^(https)[\w\W]*$/g).test(url.protocol)) {
                await this.toast.showToast(`O ticket não é seguro o suficiente. Entre em contato com o emissor.`, 'Ok', 1000, false);
                console.error(url);
                return false;
            }
        } catch {
            await this.toast.showToast(`O ticket não possui uma url válida.`, 'Ok', 1000, false);
            return false;
        }

        try {
            await axios.get(this.Ticket.value);
            return true;
        } catch (e) {
            await this.toast.showToast(`Falha na execução. [${e.toString()}]`, 'Ok', 1000, false);
            return false;
        }
    }
}

import axios from "axios";

export default async function (host: string, timeout: number = 5000): Promise<boolean> {
    return new Promise(resolve => {
        const sto = setTimeout(() => {
            resolve(false);
        }, timeout);

        axios.get(host).then(r => {
            if (r.status === 200) {
                clearTimeout(sto);
                resolve(true);
            }
        }).catch(() => {
            clearTimeout(sto);
            resolve(false);
        });
    });
}

import {initializeApp, FirebaseApp} from "firebase/app";
import {getAuth, Auth} from "firebase/auth";
import {getAnalytics, Analytics} from "firebase/analytics";
import {getPerformance, FirebasePerformance} from "firebase/performance";


const firebaseConfig = {
    apiKey: "AIzaSyDrWIDPBzJ9FCSgCjBqlpnd5tkWswfrABI",
    authDomain: "ticketclub-app.firebaseapp.com",
    projectId: "ticketclub-app",
    storageBucket: "ticketclub-app.appspot.com",
    messagingSenderId: "807234579125",
    appId: "1:807234579125:web:40e0e3533162ba0329ecfc",
    measurementId: "G-Z53433YZCN"
};

const app: FirebaseApp = initializeApp(firebaseConfig);
const auth: Auth = getAuth(app);
const analytics: Analytics = getAnalytics(app);
const performance: FirebasePerformance = getPerformance(app);

export default {
    app,
    auth,
    analytics,
    performance
};














































import {Component, Vue} from 'vue-property-decorator';
import ITicket from "@/typescript/interfaces/ITicket";

@Component({
    components: {},
    props: {
        tickets: {
            type: Array,
            required: true,
        }
    }
})
export default class extends Vue {
    private selectTicket(ticket: ITicket) {
        this.$emit('select', ticket);
    }

    private onLoadImageError(ticket: ITicket): void {
        ticket.icon = undefined;
    }
}

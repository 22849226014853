import axios, {AxiosInstance, AxiosResponse, AxiosRequestConfig} from "axios";
import {User} from "firebase/auth";
import merge from 'lodash/merge';
import store from '@/store'

const isProduction = process.env.NODE_ENV === 'production';

const getAxios = async (sendToken: boolean = true, config?: AxiosRequestConfig): Promise<AxiosInstance> => {
    const token = sendToken ? await (store.getters['GET_FIREBASE_USER'] as User).getIdToken(true) : null;

    const _axios = axios.create(merge(config || {}, {
        headers: {
            Authorization: token
        }
    }))

    _axios.interceptors.response.use(r => r, error => {
        const request = error.config

        if (request.url.includes(baseUrlProd) && !isProduction) {
            request.url = request.url.replace(baseUrlProd, baseUrlLocal);
            return axios(request);
        }

        return Promise.reject(error)
    });

    return _axios;
}

export const baseUrlLocal = `http://${window.location.hostname}:3002`
export const baseUrlProd = 'https://api.ticketclub.app'
export const baseUrl = isProduction ? baseUrlProd : baseUrlLocal;

const api = Object.freeze({
    FETCH_TICKET: async <T>(code: string): Promise<AxiosResponse<T>> => (await getAxios()).get(`${baseUrl}/v1/ticket/${code}`),
    FETCH_USER_TICKETS: async <T>(): Promise<AxiosResponse<T>> => (await getAxios()).get(`${baseUrl}/v1/ticket/all`),
    DELETE_TICKET: async <T>(code: string): Promise<AxiosResponse<T>> => (await getAxios()).delete(`${baseUrl}/v1/ticket/${code}`),
    GET_IMAGE: async <T>(fullUrl: string, auth: boolean = false): Promise<AxiosResponse<T>> => (await getAxios(auth, {
        responseType: "arraybuffer",
        timeout: 1500
    })).get(fullUrl),

    constants: {
        USER_ICON_URL: `${baseUrl}/v1/user/icon`,
        PING_URL: baseUrl
    }
});

export default api;
import Vue from 'vue'
import Vuex from 'vuex'
import api from "@/plugins/api";
import isNil from "lodash/isNil";
import isString from "lodash/isString";
import cloneDeep from "lodash/cloneDeep";
import {FirebaseApp} from "firebase/app";
import {Auth, User} from "firebase/auth";
import Dialogs from "@/typescript/enums/Dialogs";
import EventManager from "@/plugins/EventManager";
import ITicket from "@/typescript/interfaces/ITicket";
import LoginState from "@/typescript/enums/LoginState";
import TicketImageProvider from "@/plugins/TicketImageProvider";
import isUrl from "@/plugins/isUrl";
import isReachable from "@/plugins/isReachable";
import isArray from "lodash/isArray";
import dayjs from "dayjs";

const filterTickets = (ticket: ITicket) => {
    ticket.icon64 = undefined;
    return ticket;
}

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        tickets: [],
        dialogManager: undefined,
        waitingFirebase: true,
        firebaseApp: undefined,
        firebaseAuth: undefined,
        firebaseUser: undefined,
        firebaseAnalytics: undefined,
        firebasePerformance: undefined,
        screenWidth: undefined,
        screenHeight: undefined,
        userIcon: undefined,
        online: false,
        reloading: false,
        lastUpdate: "--:--"
    },
    mutations: {
        APPEND_TICKET(state, payload) {
            state.tickets.push(payload as unknown as never)
            localStorage.setItem('tickets', JSON.stringify(cloneDeep(state.tickets).map(filterTickets)));
        },
        REMOVE_TICKET(state, payload) {
            const index = state.tickets.indexOf(payload as unknown as never);

            if (index !== -1) {
                state.tickets.splice(index, 1);
            }
            localStorage.setItem('tickets', JSON.stringify(cloneDeep(state.tickets).map(filterTickets)));
        },
        CLEAR_TICKETS(state) {
            state.tickets = []
            localStorage.setItem('tickets', JSON.stringify(cloneDeep(state.tickets).map(filterTickets)));
        },
        SET_FIREBASE_APP(state, payload) {
            state.firebaseApp = payload;
        },
        SET_FIREBASE_AUTH(state, payload) {
            state.firebaseAuth = payload;
        },
        SET_FIREBASE_USER(state, payload) {
            state.firebaseUser = payload;
        },
        SET_WAITING_FIREBASE(state, payload) {
            state.waitingFirebase = payload;
        },
        SET_FIREBASE_ANALYTICS(state, payload) {
            state.firebaseAnalytics = payload;
        },
        SET_FIREBASE_PERFORMANCE(state, payload) {
            state.firebasePerformance = payload;
        },
        SET_DIALOG_MANAGER(state, payload) {
            state.dialogManager = payload;
        },
        SET_SCREEN_WIDTH(state, payload) {
            state.screenWidth = payload;
        },
        SET_SCREEN_HEIGHT(state, payload) {
            state.screenHeight = payload;
        },
        SET_USER_ICON(state, payload) {
            state.userIcon = payload;
        },
        SET_ONLINE_STATE(state, payload) {
            state.online = payload;
        },
        SET_RELOADING_STATE(state, payload) {
            state.reloading = payload;
        },
        SET_LAST_UPDATE(state, payload) {
            localStorage.setItem("lastUpdate", payload);
            state.lastUpdate = payload;
        }
    },
    actions: {
        async INSERT_TICKET(store, payload: ITicket) {
            try {
                if (payload?.expireDate && dayjs(payload.expireDate).tz("Etc/UTC", true).isBefore(dayjs())) {
                    console.log("Ticket expired.", {
                        title: payload.title,
                        desc: payload.desc
                    });
                    return;
                }
            } catch (e) {
                console.error(e)
            }

            if (isNil(payload.icon64) && isString(payload.icon) && isUrl(payload.icon)) {
                try {
                    payload.icon64 = await TicketImageProvider.Instance.getImage(payload.icon)
                } catch (e) {
                    console.log(e)
                }
            }

            store.commit('APPEND_TICKET', payload);
        },
        REMOVE_TICKET(store, payload: string) {
            const ticket = store.state.tickets.filter((ticket: ITicket) => ticket.code === payload).pop();

            if (!isNil(ticket)) {
                store.commit('REMOVE_TICKET', ticket);
            }
        },
        CLEAR_STORE_TICKETS(store) {
            store.commit('CLEAR_TICKETS');
        },
        UPDATE_FIREBASE_APP(store, payload) {
            store.commit('SET_FIREBASE_APP', payload);
        },
        UPDATE_FIREBASE_AUTH(store, auth: Auth) {
            auth.useDeviceLanguage();
            auth.onAuthStateChanged(userData => {
                store.dispatch('UPDATE_FIREBASE_USER', userData);
                store.dispatch('UPDATE_WAITING_FIREBASE', false);
            });

            store.commit('SET_FIREBASE_AUTH', auth);
        },
        UPDATE_FIREBASE_USER(store, payload) {
            store.commit('SET_FIREBASE_USER', payload);
        },
        UPDATE_WAITING_FIREBASE(store, payload) {
            store.commit('SET_WAITING_FIREBASE', payload);
        },
        UPDATE_FIREBASE_ANALYTICS(store, payload) {
            store.commit('SET_FIREBASE_ANALYTICS', payload);
        },
        UPDATE_FIREBASE_PERFORMANCE(store, payload) {
            store.commit('SET_FIREBASE_PERFORMANCE', payload);
        },
        async LOGOUT(store) {
            await (store.state.firebaseAuth as unknown as Auth).signOut()
            TicketImageProvider.Instance.deleteCacheFrom(api.constants.USER_ICON_URL);
            await store.dispatch('UPDATE_FIREBASE_USER', null);
            await store.dispatch('CLEAR_STORE_TICKETS');
            store.commit('SET_LAST_UPDATE', "--:--");
            store.commit('SET_USER_ICON', "");
            localStorage.clear();
        },
        SETUP_DIALOG_MANAGER(store) {
            store.commit('SET_DIALOG_MANAGER', new EventManager(Object.values(Dialogs)));
        },
        async FETCH_USER_TICKETS(store) {
            store.commit("SET_RELOADING_STATE", true);
            const {data: tickets} = await api.FETCH_USER_TICKETS<ITicket[]>();

            tickets.forEach(ticket => {
                if (!store.getters['GET_TICKETS'].some((value: ITicket) => value.code === ticket.code)) {
                    store.dispatch('INSERT_TICKET', ticket);
                }
            });

            store.getters['GET_TICKETS'].forEach((ticket: ITicket) => {
                if (!tickets.some((value: ITicket) => value.code === ticket.code)) {
                    store.dispatch('REMOVE_TICKET', ticket.code);
                }
            });
            store.commit("SET_RELOADING_STATE", false);
            store.commit("SET_LAST_UPDATE", (() => {
                const date = new Date();
                return `${date.toLocaleDateString()} as ${date.toLocaleTimeString()}`
            })());
        },
        UPDATE_SCREEN_WIDTH(store, payload) {
            store.commit('SET_SCREEN_WIDTH', payload);
        },
        UPDATE_SCREEN_HEIGHT(store, payload) {
            store.commit('SET_SCREEN_HEIGHT', payload);
        },
        async FETCH_USER_ICON(store) {
            if (store.getters['GET_LOGIN_STATE'] !== LoginState.LoggedIn) return;
            const photo = await TicketImageProvider.Instance.getImage(api.constants.USER_ICON_URL, true);
            store.commit('SET_USER_ICON', photo)
        },
        async CHECK_INTERNET_CONNECTION(store) {
            const online = await isReachable(api.constants.PING_URL, 1500);
            store.commit("SET_ONLINE_STATE", online);
        },
        RESTORE_LOCAL_TICKETS(store) {
            const raw_tickets: string | null = localStorage.getItem("tickets");
            let tickets: ITicket[] | null = null;

            try {
                tickets = JSON.parse(raw_tickets as string);
            } catch {
                console.log("No tickets were found.");
            }

            if (!isNil(tickets) && isArray(tickets)) {
                store.dispatch('CLEAR_STORE_TICKETS');

                for (const ticket of tickets) {
                    store.dispatch('INSERT_TICKET', ticket);
                }
            }
        }
    },
    getters: {
        GET_TICKETS(state): ITicket[] {
            return state.tickets as unknown as ITicket[];
        },
        GET_LOGIN_STATE(state): LoginState {
            if (isNil(state.firebaseUser) && state.waitingFirebase) {
                return LoginState.Pending;
            } else if (isNil(state.firebaseUser)) {
                return LoginState.LoggedOut;
            } else if (!isNil(state.firebaseUser)) {
                return LoginState.LoggedIn;
            }

            return LoginState.Unknown;
        },
        GET_FIREBASE_APP(state): FirebaseApp {
            return state.firebaseApp as unknown as FirebaseApp;
        },
        GET_FIREBASE_AUTH(state): Auth {
            return state.firebaseAuth as unknown as Auth;
        },
        GET_FIREBASE_USER(state): User {
            return state.firebaseUser as unknown as User;
        },
        GET_DIALOG_MANAGER(state): EventManager {
            return state.dialogManager as unknown as EventManager;
        },
        GET_SCREEN_WIDTH(state): number {
            return state.screenWidth as unknown as number;
        },
        GET_SCREEN_HEIGHT(state): number {
            return state.screenHeight as unknown as number;
        },
        GET_IS_PWA(): boolean {
            return ["fullscreen", "standalone", "minimal-ui"].some(
                (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
            );
        },
        GET_USER_ICON_CACHED(state): string {
            return state.userIcon || ""
        },
        GET_ONLINE_STATE(state): boolean {
            return state.online;
        },
        GET_RELOADING_STATE(state): boolean {
            return state.reloading;
        },
        GET_LAST_UPDATE(state): string {
            return state.lastUpdate;
        }
    },
    modules: {}
})

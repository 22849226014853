enum Dialogs {
    AddDialog = 'AddDialog',
    LoginDialog = 'LoginDialog',
    TicketCard = 'TicketCard',
    PwaDialog = 'PwaDialog',
    UpdateDialog = 'UpdateDialog',
    NetworkDialog = 'NetworkDialog',
    LocationDialog = 'LocationDialog',
}

export default Dialogs;
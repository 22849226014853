



















import {Component, Vue} from 'vue-property-decorator';

@Component({
    components: {},
})
export default class extends Vue {
    private timeout: number = 2500;
    private message: string = "";
    private action: string = "";
    private doAction: boolean = true;
    private show: boolean = false;

    public async showToast(message: string, action: string, timeout: number = 2500, doAction: boolean = true): Promise<void> {
        this.timeout = timeout;
        this.message = message;
        this.action = action;
        this.doAction = doAction;
        this.hideToast();
        await this.$nextTick();
        this.show = true;
    }

    public hideToast(): void {
        this.show = false;
    }

    public emitAction(): void {
        this.show = false;

        if(!this.doAction)
            return;

        this.$emit('action');
    }
}

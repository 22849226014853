import Callback from "@/typescript/interfaces/Callback";

export default class EventManager {
    private readonly CallbackList: Map<string, Callback[]>;
    private readonly DispatchHistory: Map<string, unknown[][]>;

    constructor(eventList: string[]) {
        this.CallbackList = new Map();
        this.DispatchHistory = new Map();
        eventList.forEach(event => {
            this.CallbackList.set(event, [])
            this.DispatchHistory.set(event, [])
        });
    }

    dispatch(eventName: string, ...args: unknown[]): void {
        if (this.CallbackList.has(eventName)) {
            const list = this.CallbackList.get(eventName) as Callback[];
            list.forEach(callback => {
                try {
                    callback(...args as never[]);
                } catch (e) {
                    console.error(e);
                }
            });
            this.DispatchHistory.get(eventName)?.push(args);
        } else {
            throw new Error(`Unknown event ${eventName}.`);
        }
    }

    on(eventName: string, listener: Callback): void {
        if (this.CallbackList.has(eventName)) {
            const list = this.CallbackList.get(eventName) as Callback[];
            list.push(listener);
            this.CallbackList.set(eventName, list);
            this.DispatchHistory.get(eventName)?.forEach(iteration => {
                listener(...iteration as never[]);
            });
        } else {
            throw new Error(`Unknown event ${eventName}.`);
        }
    }

    remove(eventName: string, listener: Callback): void {
        if (this.CallbackList.has(eventName)) {
            const list = this.CallbackList.get(eventName) as Callback[];
            const index = list.indexOf(listener);
            list.splice(index, 1);
            this.CallbackList.set(eventName, list);
        } else {
            throw new Error(`Unknown event ${eventName}.`);
        }
    }
}
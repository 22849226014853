







































import {Component, Vue} from 'vue-property-decorator';
import {Auth, GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import LoginState from "@/typescript/enums/LoginState";
import EventManager from "@/plugins/EventManager";
import Dialogs from "@/typescript/enums/Dialogs";

@Component({
    components: {},
})
export default class extends Vue {
    private show: boolean = false;
    private label: string = '';

    private get dialogManager(): EventManager {
        return this.$store.getters['GET_DIALOG_MANAGER'];
    }

    get auth(): Auth {
        return this.$store.getters['GET_FIREBASE_AUTH'];
    }

    get loginState(): LoginState {
        return this.$store.getters['GET_LOGIN_STATE'];
    }

    mounted(): void {
        this.dialogManager.on(Dialogs.LoginDialog, (text: string | undefined) => {
            this.label = text || '';
            this.show = false;
            this.login();
            //As of now only one login provider is used (Google) so it is not necessary to show the dialog to the user.
        });
    }

    async login(): Promise<void> {
        if (this.loginState !== LoginState.LoggedOut) {
            this.show = false;
            return;
        }

        const provider = new GoogleAuthProvider();
        await signInWithPopup(this.auth, provider);
    }
}











































import {Component, Vue} from 'vue-property-decorator';
import LoginState from "@/typescript/enums/LoginState";
import IContextMenuItem from "@/typescript/interfaces/IContextMenuItem";
import ContextMenuAction from "@/typescript/enums/ContextMenuAction";

@Component
export default class extends Vue {
    get items(): IContextMenuItem[] {
        return [
            {
                name: 'Aviso',
                icon: 'mdi-alert-circle',
                color: 'red',
                action: ContextMenuAction.Warning,
                disabled: false,
                hide: this.isPwa
            },
            {
                name: `${this.isDarkTheme ? 'Modo claro' : 'Modo escuro'}`,
                icon: `mdi-${this.isDarkTheme ? 'brightness-7' : 'brightness-3'}`,
                action: ContextMenuAction.Theme,
                disabled: false
            },
            {
                name: 'Adicionar',
                icon: 'mdi-plus',
                action: ContextMenuAction.Add,
                disabled: false
            },
            {
                name: 'Entrar',
                icon: 'mdi-login',
                action: ContextMenuAction.Login,
                disabled: false,
                hide: this.IsLoggedIn
            },
            {
                name: 'Sair',
                icon: 'mdi-logout',
                action: ContextMenuAction.Logout,
                disabled: false,
                hide: this.IsLoggedOut
            }
        ]
    }

    get loginState(): LoginState {
        return this.$store.getters['GET_LOGIN_STATE'];
    }

    get IsLoggedIn(): boolean {
        return this.loginState === LoginState.LoggedIn;
    }

    get IsLoggedOut(): boolean {
        return this.loginState === LoginState.LoggedOut;
    }

    get isPwa(): boolean {
        return this.$store.getters['GET_IS_PWA'];
    }

    get isOnline(): boolean {
        return this.$store.getters['GET_ONLINE_STATE'];
    }

    get isDarkTheme(): boolean {
        return this.$vuetify.theme.dark;
    }

    get isOnAddRoute(): boolean {
        return this.$route.path.includes("/add/");
    }

    private emitEvent(action: ContextMenuAction) {
        this.$emit('click', action);
    }
}

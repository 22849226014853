



































import {Component, Vue} from 'vue-property-decorator';
import EventManager from "@/plugins/EventManager";
import Dialogs from "@/typescript/enums/Dialogs";

@Component({
    components: {},
})
export default class extends Vue {
    private show: boolean = false;

    private get dialogManager(): EventManager {
        return this.$store.getters['GET_DIALOG_MANAGER'];
    }

    mounted(): void {
        this.dialogManager.on(Dialogs.UpdateDialog, () => {
            this.show = true;
        });
    }

    reloadPage(): void {
        window.location.reload()
    }
}

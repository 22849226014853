






















































import {Component, Vue} from 'vue-property-decorator';

@Component({
    components: {},
})
export default class extends Vue {
    start(): void {
        localStorage.setItem("welcome", "1");
        this.$router.push("/");
    }
}

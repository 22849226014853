import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from "@/plugins/firebase";
import TicketImageProvider from "@/plugins/TicketImageProvider";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

dayjs.extend(timezone)
dayjs.extend(utc)
Vue.config.productionTip = false

new TicketImageProvider()
store.dispatch('SETUP_DIALOG_MANAGER').catch(console.error);
store.dispatch('UPDATE_FIREBASE_APP', firebase.app).catch(console.error);
store.dispatch('UPDATE_FIREBASE_AUTH', firebase.auth).catch(console.error);
store.dispatch('UPDATE_FIREBASE_ANALYTICS', firebase.analytics).catch(console.error);
store.dispatch('UPDATE_FIREBASE_PERFORMANCE', firebase.performance).catch(console.error);

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
